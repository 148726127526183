<template>
  <div id="SolutionTwo">
    <!-- SolutionTwo头部内容 -->
    <div class="SolutionTwoHeader">
      <!-- 头部组件 -->
      <Header />
      <!-- 视频实现自动播放、循环播放 -->
      <video controls="controls" loop autoplay src="~assets/video/三象新版_1.mp4" width="100%" height="620px"></video>
    </div>
    <!-- SolutionTwo中间内容 -->
    <div class="SolutionTwoCenter">
      <!-- 产品简介 -->
      <div class="producrPro">
        <div class="protectProTit">产品简介</div>
        <div class="protectProCon">
          <img src="~assets/img/SolutionTwo/纺织@2x.png" alt="" />
          <ul class="producrProRight">
            <li v-for="(item, index) in producrProRight" :key="index">
              <div class="producrProRiTit">{{ item.Title }}</div>
              <div class="producrProRiCon">
                {{ item.Text }}
              </div>
            </li>
          </ul>
        </div>
        <ul class="rate">
          <li v-for="(item, index) in rate" :key="index">
            <div class="rateNum">
              {{ item.num }}
            </div>
            <div class="ratePro">{{ item.text }}</div>
          </li>
        </ul>
      </div>
      <!-- 产品价值 -->

      <div class="MathCompany">
        <div class="MathCompanyTit">
          产品价值
        </div>
        <img
          class="MathCompanyImg"
          src="~assets/img/SolutionTwo/产品价值@2x.png"
          alt=""
        />
        <ul class="MathCompanyDetail">
          <li v-for="(item, index) in MathCompanyDetail" :key="index">
            <div class="headTop">
              <div class="headTopTit">
                {{ item.Titles }}
              </div>
              <img class="MathComIcons" :src="item.icons" alt="" />
            </div>
            <div class="MathComConTie">
              {{ item.Title }}
            </div>
            <div class="MathComContent" v-html="item.text"></div>
          </li>
        </ul>
      </div>
    </div>
    <Footer />
    <!-- 回到顶部 -->
    <el-backtop
      ><img
        src="~assets/img/SolutionTwo/icon-一键置顶@2x.png"
        alt="icon-回到顶部@2x.png"
    /></el-backtop>
  </div>
</template>
<script>
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
export default {
  name: "SolutionTwo",
  data() {
    return {
      producrProRight: [
        {
          Title: "产品特点",
          Text:
            " 纺织行业数字化管控系统主要面向线带纺织行业企业，通过织造设备传感改造和网络建设，构建覆盖主要织造设备的物联网监控系统，实现设备状态、作业过程、生产量的实时采集和统计，以及实现覆盖排产、派工、生产执行、检验、设备监控等全流程的数字化管理。 "
        },
        {
          Title: "产品荣誉",
          Text:
            "该项目被列为浙江省2020年软件产业高质量发展重点项目，入选工信部2020年全国优秀工业APP项目，打造的澳亚织造数字化工厂项目被评为金华市2020年“数字化车间”和“物联网工厂”示范项目。"
        }
      ],
      MathCompanyDetail: [
        {
          Titles: "排产计划",
          icons: require("assets/img/SolutionTwo/icon-排产计划@2x.png"),
          Title: "系统辅助排产，提升排产准确性和机台利用率",
          text:
            "数据实时同步。<br> 灵活的拆单、补单，续接，分支流程的管控。<br>实时了解设备生产状态，辅助快速排产。"
        },
        {
          Titles: "生产派工",
          icons: require("assets/img/SolutionTwo/icon-生产派工@2x.png"),
          Title: "自动派工到机台，员工实时接收任务",
          text:
            "指定工单，时间，人、机器，完成任务分配。<br>批量派工，员工刷卡即可查看当天任务，进行上机操作。支持回退，撤销派工。<br>量产派工以人员为单位，改机穿纱以机台为单位派工。"
        },
        {
          Titles: "生产执行",
          icons: require("assets/img/SolutionTwo/icon-生产执行@2x.png"),
          Title: "生产过程透明化，订单进度实时监控",
          text:
            "可以随时查询生产执行各个工序状态、起止时间，跟进工单生产进展，分析员工工作效率。<br>控制生产执行各个节点的执行、回退操作应对诸如中途换机生产等异常情况。<br>实时查看采集数据，同步包装入库数据，及时跟进工单生产进度。"
        },
        {
          Titles: "数据采集",
          icons: require("assets/img/SolutionTwo/icon-数据采集@2x.png"),
          Title: "自动采集产量和工时，数据同步工资系统",
          text:
            "员工确认每日采集的工时，可申请修正工时误差，保留修正日志。"
        },
        {
          Titles: "质量管控",
          icons: require("assets/img/SolutionTwo/icon-质量管控@2x.png"),
          Title: "小程序包装检验，质量追溯到人",
          text:
            "支持通过小程序巡检，包装检验，通过条码追踪。<br>精确到条带检验，支持对条带数进行校验，防止误填。 通过小程序进行包装检验的记录，直接算出废品率。"
        },
        {
          Titles: "异常监控",
          icons: require("assets/img/SolutionTwo/icon-异常监控@2x.png"),
          Title: "各类生产异常及时报警处理",
          text:
            "生产异常(离线，断经、断纬、卷带，设备故障)上报。<br>故障报修。<br>设备维护保养。"
        },
        {
          Titles: "生产报表",
          icons: require("assets/img/SolutionTwo/icon-生产报表@2x.png"),
          Title: "丰富的自定义报表，辅助管理层决策",
          text:
            "准确统计挡车工工时和工单的产量，解决超产、工时统计不准确的问题。<br>自定义报表字段、顺序，适应不同角色的关注要点。<br>支持多条件查询，汇总、筛选，导出。"
        },
        {
          Titles: "可视化",
          icons: require("assets/img/SolutionTwo/icon-可视化@2x.png"),
          Title: "丰富的自定义报表，辅助管理层决策",
          text:
            "对设备运行状态和时长进行监控。<br>对设备当前生产的工单的工序执行状态进行监控。包括各个工序的起止日期，执行人员，执行时间。<br>对设备OEE，每小时的转数，故障占比统计。<br>对量产中工单的产量和生产异常进行实时监控。<br>对穿纱挂机任务计划进行跟踪，对补单和续接单统计。"
        }
      ],
      rate: [
        { num: "30%", text: "挡车工效率提升" },
        { num: "30%", text: "设备利用率提升" },
        { num: "10%", text: " 生产的周期缩短" },
        { num: "10%", text: " 原材料浪费减少" }
      ]
    };
  },
  components: {
    Header,
    Footer
  }
};
</script>
<style lang="less" scoped>
#SolutionTwo {
width: 1583px;
  background-color: #f8f9fb;
  margin: 0 auto;
  // SolutionTwo头部样式
  .SolutionTwoHeader {
    width: 1583px;
    height: 620px;
  /*   background: url("~assets/img/SolutionTwo/banner@2x.png") no-repeat;
    background-size: cover;
    background-position: center; */
  }
  //   SolutionTwo中间内容
  .SolutionTwoCenter {
    width: 1220px;
    margin: 80px auto 102px;
    // 产品简介
    .producrPro {
      .protectProTit {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 27px;
        color: rgba(51, 51, 51, 0.85);
        text-align: center;
      }
      .protectProCon {
        height: 390px;
        margin-top: 30px;
        display: flex;
        // overflow: hidden;
        img {
          width: 572px;
          height: 390px;
          border-radius: 10px;
        }
        .producrProRight {
          height: 390px;
          padding: 0;
          margin: 0px 0px 0px 48px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          // align-content: center;
          li {
            &:first-child {
              margin-bottom: 30px;
            }
            .producrProRiTit {
              width: 92px;
              height: 36px;
              background: #e7f3fe;
              border-radius: 6px;
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              line-height: 36px;
              color: #1e90ff;
              text-align: center;
            }
            .producrProRiCon {
              width: 600px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 28px;
              color: rgba(51, 51, 51, 0.85);
              margin-top: 14px;
            }
          }
        }
      }
      .rate {
        height: 142px;
        background: #fff;
        margin-top: 20px;
        padding: 0;
        display: flex;
        li {
          width: 305px;
          height: 142px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .rateNum {
            font-size: 46px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #1e90ff;
          }
          .ratePro {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: rgba(51, 51, 51, 0.85);
            margin-top: 14px;
          }
        }
      }
    }
    // 产品价值
    .MathCompany {
      margin-top: 80px;
      .MathCompanyTit {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 27px;
        color: rgba(51, 51, 51, 0.85);
        text-align: center;
      }
      .MathCompanyImg {
        width: 1220px;
        height: 360px;
        margin-top: 30px;
      }
      .MathCompanyDetail {
        height: 834px;
        margin-top: 30px;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        li {
          width: 390px;
          height: 232px;
          background-color: #fff;
          // background: url("~assets/img/SolutionTwo/BG@2x.png") no-repeat;
          // background-size: cover;
          // background-position: center;
          margin-right: 25px;
          &:nth-child(-n + 3) {
            height: 294px;
          }
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            height: 204px;
          }
          &:nth-child(n + 7) {
            height: 286px;
          }
          &:nth-child(3n) {
            margin-right: 0px;
          }
          &:last-child {
            .headTop {
              .MathComIcons {
                width: 36px;
                height: 36px;
                margin: 10px 0 0 239px;
              }
            }
          }

          .headTop {
            overflow: hidden;
            display: flex;
            height: 58px;
            background: url("~assets/img/SolutionTwo/BG@2x.png") no-repeat;
            background-size: cover;

            .headTopTit {
              font-size: 18px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              line-height: 28px;
              color: #1e90ff;
              margin: 16px 0 0 30px;
            }
            .MathComIcons {
              width: 36px;
              height: 36px;
              margin: 10px 0 0 221px;
            }
          }
          .MathComConTie {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: rgba(51, 51, 51, 0.85);
            margin: 20px 0 0 30px;
          }
          .MathComContent {
            height: 70px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 28px;
            color: rgba(51, 51, 51, 0.45);
            margin: 8px 20px 0 30px;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.el-backtop {
  img {
    width: 30px;
    height: 30px;
  }
}
</style>
